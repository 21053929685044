@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


/* tailwind.css */
@layer components {
	.input-container {
	  @apply relative mb-4 ;
	}
  
	.input-container label {
	  @apply absolute top-1/2 left-0 transform -translate-y-1/2 transition-all duration-200 ease-in-out pointer-events-none text-base;
	}
  
	.input-container input:focus + label,
	.input-container input:not(:placeholder-shown) + label {
	  @apply -top-4 text-xs text-blue-500 ;
	}
  
	.input-container input:focus {
	  @apply transform scale-105 border-blue-500;
	}
  }
  

@layer base {
	html {
		font-family: 'Inter', sans-serif;
		line-height: 1.3;
	}
	table {
	}
	th,
	td {
		@apply py-3 px-2.5 text-sm text-left border-y border-gray-200;
	}
	thead {
		@apply bg-neutral-100;
	}
	th {
		@apply font-semibold;
	}
	a {
		@apply text-sky-500 hover:underline;
	}
}


.MusicD {
    display: flex;
    flex-wrap: nowrap; /* Default value for flex-wrap */
}

/* Medium screen */
@media (max-width: 850px) {
    .MusicD {
        flex-direction: column;
    }
}

/* Large screen */
@media (min-width: 1024px) {
    .MusicD {
        flex-direction: row;
    }
}

.modal-con {
	background: url('/public/assets/Group\ 349.png');
	padding: 0;
	margin: 0;
	background-repeat: no-repeat;
	background-size: cover;
	
}
a{
	text-decoration: none;
}

.dash{
	overflow-x: scroll;
}



.custom-swal-popup {
    @apply w-96 h-96 p-8 rounded-md
}

.custom-swal-title {
    @apply  text-lg; 
}

.custom-swal-icon {
    @apply text-xl; 
}
